<template>
  <q-page
    :style="{
      transform: page.regions.some((t: any) => t.type === 'video-list')
        ? 'translateY(-5rem)'
        : null,
      'margin-bottom': '-5rem'
    }"
  >
    <!-- <template v-for="item in [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 44,
  48, 52, 56, 60, 64, 72, 80, 96,
]" :key="item">
      {{ `
.-gap-x-${item} > * {
  margin-left: ${0.125 * item}rem;
  margin-right: ${0.125 * item}rem;
}
.-gap-x-${item} > *:first-child {
  margin-left: 0;
  margin-right: ${0.125 * item * 2}rem;
}
.-gap-x-${item} > *:last-child {
  margin-left: ${0.125 * item * 2}rem;
  margin-right: 0;
}
.-gap-y-${item} > * {
  margin-top: ${0.125 * item}rem;
  margin-bottom: ${0.125 * item}rem;
}
.-gap-y-${item} > *:first-child {
  margin-top: 0;
  margin-bottom: ${0.125 * item * 2}rem;
}
.-gap-y-${item} > *:last-child {
  margin-top: ${0.125 * item * 2}rem;
  margin-bottom: 0;
}
.-gap-${item} > * {
  margin-left: ${0.125 * item}rem;
  margin-right: ${0.125 * item}rem;
  margin-top: ${0.125 * item}rem;
  margin-bottom: ${0.125 * item}rem;
}
      ` }}
    </template> -->

    <template v-for="region in page.regions" :key="region.id">
      <template v-if="region.type === 'video-list'">
        <template
          v-for="video in region.children?.filter((t:any) => t.isActive)"
          :key="video.id"
        >
          <div
            class="relative"
            style="width: calc((100vw)); height: calc((100vw) / 16.5 * 9)"
          >
            <div class="absolute top-0 left-0 right-0 bottom-0">
              <vue-video-player
                class="video-player vjs-big-play-centered"
                style="width: calc((100vw)); height: calc((100vw) / 16.5 * 9)"
                :src="video.image"
                :poster="video.image.replace(/\.mp4$/i, '.jpg')"
                :muted="!videoFullscreen"
                :controls="videoFullscreen"
                :volume="0.6"
                :playback-rates="[0.7, 1.0, 1.5, 2.0]"
                :loop="true"
                crossorigin="anonymous"
                playsinline
                @mounted="handleMounted($event)"
                @fullscreenchange="handleFullscreenChanged"
              >
              </vue-video-player>
            </div>
            <div
              class="absolute top-0 left-0 right-0 bottom-0 z-10 flex justify-center items-center flex-col gap-8"
            >
              <div v-if="video.summary" class="text-5xl text-primary">
                {{ video.summary }}
              </div>
              <q-btn
                class="text-primary"
                v-if="video.url"
                :to="video.url"
                :label="video.content"
                outline
                no-caps
                @click="(e) => onClickVideoButton(e, video.url)"
              ></q-btn>
            </div>
          </div>
        </template>
      </template>

      <template v-if="region.type === 'carousel'">
        <sv-carousel
          v-if="region.children?.length"
          v-model="region.value"
          :regions="region.children!"
          height="calc(100vh - 5rem)"
          class="w-full"
          transition-prev="jump-right"
          transition-next="jump-left"
          swipeable
          animated
          control-color="#fff"
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
          navigation-icon="mdi-radiobox-blank"
          :navigation="region.children?.length > 1"
          :arrows="region.children?.length > 1"
          infinite
          padding
        >
          <template #navigation-icon="{ active, onClick }">
            <q-btn
              v-if="active"
              class="indicator w-14 h-2"
              padding="0"
              dense
              @click="onClick"
            />
            <q-btn
              v-else
              class="bg-gray-500 w-14 h-2"
              padding="0"
              dense
              @click="onClick"
            />
          </template>
        </sv-carousel>
      </template>

      <template v-if="region.type === 'product-list'">
        <div
          v-if="region.extraProperties?.linkPageId"
          class="flex justify-center bg-sky-50"
        >
          <div class="container lg:px-8 xl:px-0">
            <SvProductENTabs
              v-if="isEnglish"
              class="mt-8 mb-20"
              :pages="productPages(region.extraProperties.linkPageId)"
            ></SvProductENTabs>
            <sv-product-tabs
              v-else
              class="mt-8 mb-16"
              :pages="productPages(region.extraProperties.linkPageId)"
            ></sv-product-tabs>
          </div>
        </div>
      </template>

      <template v-if="region.type === 'document-list'">
        <div class="flex justify-center pt-16 pb-24">
          <div class="container lg:px-8 xl:px-0">
            <div class="flex justify-between items-center">
              <div class="flex items-center">
                <img
                  v-if="region.icon"
                  :src="region.icon"
                  class="w-8 h-8 mx-2 inline"
                  :alt="region.name"
                />
                <span class="text-2xl">{{ region.name }}</span>
              </div>
              <router-link
                to="/news"
                class="flex items-center -gap-x-2 text-green-600"
              >
                <span>{{ t('Index.more') }}</span>
                <q-icon
                  size="20px"
                  name="mdi-arrow-right-drop-circle-outline"
                />
              </router-link>
            </div>
            <sv-document-cards
              :region="region"
              :articles="articles"
            ></sv-document-cards>
          </div>
        </div>
      </template>

      <template v-if="region.type === 'image-list'">
        <div v-if="region.children?.length" class="flex justify-center py-8">
          <div class="container lg:px-8 xl:px-0">
            <div class="flex justify-between items-center">
              <div class="flex items-center">
                <img
                  v-if="region.icon"
                  :src="region.icon"
                  class="w-8 h-8 mx-2 inline"
                  :alt="region.name"
                />
                <span class="text-2xl w-">{{ region.name }}</span>
              </div>
            </div>
            <sv-thumbnail
              v-if="region.type === 'image-list' && region.children?.length"
              :regions="region.children"
              grid-class="2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2"
              imageHeight="108px"
              ratio="16/9"
              flat
              :row="3"
              :show-desc="false"
              :show-dialog="false"
              autoplay
            >
            </sv-thumbnail>
          </div>
        </div>
      </template>

      <template v-if="region.type === 'data-list'">
        <div
          v-if="region.extraProperties.theme === '首页产品样式'"
          class="w-full h-dvh flex justify-center items-center"
          :style="background(region.image)"
        >
          <div
            class="grid grid-cols-12 grid-rows-12 gap-2 2xl:w-4/6 2xl:h-3/5 2xl:gap-4"
          >
            <template v-for="(data, i) in region.children" :key="data.id">
              <div
                :class="`${tiles[i].boxcls} rounded-xl text-primary`"
                :style="tiles[i].boxstyle"
              >
                <div
                  :class="`relative w-full h-full flex justify-between ${
                    tiles[i].indicator === 'dash' ? 'flex-col' : ''
                  }`"
                >
                  <div
                    :class="`flex flex-col justify-center pl-4 py-4 ${tiles[i].cls}`"
                    style="width: 80%"
                  >
                    <div
                      :class="`tile-title ${tiles[i].maincls} font-extrabold`"
                    >
                      {{ data.name }}
                    </div>
                    <div
                      :class="`tile-summary ${tiles[i].subcls}`"
                      v-html="data.summary.replace(/[\r\n]/g, '<br />')"
                    ></div>
                  </div>
                  <template v-if="tiles[i].indicator === 'dot'">
                    <div class="relative">
                      <div
                        class="absolute right-4 top-4 tile-indicator mt-0.5 w-1 h-1"
                      ></div>
                      <q-btn
                        class="tile-btn absolute right-2 top-2 animate__animated animate__fadeInLeft"
                        :to="data.url"
                        icon="mdi-chevron-right"
                        size="xs"
                        round
                        flat
                      ></q-btn>
                    </div>
                  </template>
                  <template v-else-if="tiles[i].indicator === 'dash'">
                    <div class="flex justify-between items-end p-4">
                      <div class="tile-indicator w-5 h-1"></div>
                      <q-btn
                        class="tile-btn animate__animated animate__fadeInLeft"
                        :to="data.url"
                        icon="mdi-chevron-right"
                        size="xs"
                        round
                        flat
                      ></q-btn>
                    </div>
                  </template>
                  <img
                    v-else-if="data.icon"
                    class="mt-4 mr-4"
                    :src="data.icon"
                    style="width: 24px; height: 24px"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>

      <template v-if="region.type === 'summary'">
        <div
          v-if="region.extraProperties.theme === '首页交互'"
          id="message"
          class="flex justify-center h-dvh"
        >
          <div class="container grid grid-cols-2 items-center text-primary">
            <div class="flex justify-center text-5xl font-bold">
              {{ region.name }}
            </div>
            <q-form
              ref="formRef"
              class="py-8 q-gutter-sm text-base 2xl:text-xl"
              @submit="handleSubmit"
              @reset="handleReset"
            >
              <q-input
                v-model="model.name"
                :label="t('Contact.form.name')"
                :rules="[(val) => val && val.length > 0]"
                :dark="dark"
              />
              <q-input
                class="flex-1"
                v-model="model.email"
                type="tel"
                :label="t('Contact.form.email.lable')"
                :rules="[
                  (val) =>
                    (val &&
                      val.length > 0 &&
                      /^[A-Za-z0-9!#$%&'+/=?^_`{|}~-]+(.[A-Za-z0-9!#$%&'+/=?^_`{|}~-]+)*@([A-Za-z0-9]+(?:-[A-Za-z0-9]+)?.)+[A-Za-z0-9]+(-[A-Za-z0-9]+)?$/.test(
                        val
                      )) ||
                    t('Contact.form.email.rules'),
                ]"
                :dark="dark"
              />
              <q-input
                v-model="model.content"
                type="textarea"
                :label="t('Contact.form.content')"
                :rules="[(val) => val && val.length > 0]"
                :dark="dark"
                autogrow
              />
              <div class="font-bold">
                {{ t('Contact.form.about') }}
              </div>
              <div class="flex flex-col text-sm 2xl:text-base">
                <template v-for="product in productOptions" :key="product.id">
                  <q-checkbox
                    v-model="model.about"
                    :val="product.label"
                    :label="product.label"
                    :dark="dark"
                    size="sm"
                  ></q-checkbox>
                </template>
              </div>

              <q-btn
                class="w-full mt-4 text-base text-primary horizontal-gradient highlight hvr-grow-shadow"
                :dark="dark"
                :loading="state.loading"
                type="submit"
                no-caps
                >{{ t('Contact.form.submit') }}</q-btn
              >
            </q-form>
          </div>
        </div>
      </template>
    </template>
  </q-page>
</template>

<script lang="ts">
import { usePageStore } from 'stores/modules/page';
import { useArticleStore } from 'stores/modules/article';
import { RegionDto } from 'src/api/types';

const dark = process.env.THEME === 'dark';

export default {
  async preFetch({ store, currentRoute }) {
    const pageStore = usePageStore(store);
    await pageStore.fetch({ url: currentRoute.path });

    const articleStore = useArticleStore(store);
    await articleStore.fetchList({
      isActive: true,
      skipCount: 0,
      maxResultCount: 10,
    });
  },
};
</script>

<script setup lang="ts">
import { ref, computed, shallowRef, reactive, onMounted } from 'vue';
import { useMeta, Notify } from 'quasar';
import { treeToList } from 'src/utils/tree';
import SvCarousel from 'src/components/SvCarousel.vue';
import SvProductTabs from 'src/components/SvProductTabs.vue';
import SvProductENTabs from 'src/components/SvProductENTabs.vue';
import SvDocumentCards from 'src/components/SvDocumentCards.vue';
import SvThumbnail from 'src/components/SvThumbnail.vue';
import { useMetaStore } from 'src/stores/modules/meta';
import { useI18n } from 'vue-i18n';
import { create, withEmail } from 'src/api/Message';
import VueScrollTo from 'vue-scrollto';
import { useRoute } from 'vue-router';

const route = useRoute();
onMounted(() => {
  if (route.hash) {
    VueScrollTo.scrollTo(route.hash, {
      offset: -124,
    });
  }
});

const { locale, t } = useI18n();
const isEnglish = computed(() => locale.value === 'en-US');

// const videoPlayerVisible = ref<boolean>(false);
const videoFullscreen = ref<boolean>(false);
// const carouselAutoplay = ref<boolean | number>(8000);

const player = shallowRef();
const handleMounted = (payload: any) => {
  player.value = payload.player;
  player.value?.play();
};

// const handlePlay = () => {
//   const button = document.getElementsByClassName(
//     'vjs-fullscreen-control'
//   )[0] as HTMLElement;
//   button.click();
// };

const handleFullscreenChanged = () => {
  videoFullscreen.value = !videoFullscreen.value;
};

// const handleCarouseUpdated = (value: string | number) => {
//   videoPlayerVisible.value = value === 1;
// };

const pageStore = usePageStore();
const page = computed(() => pageStore.page);

const metaStore = useMetaStore();
useMeta(
  Object.assign({}, metaStore.meta, {
    title: page.value.title,
    titleTemplate: (title: string) => `${title} - ${metaStore.meta.title}`,
  })
);

const articleStore = useArticleStore();
const articles = computed(() => articleStore.articles);

const menus = computed(() => pageStore.menus);
const productPages = (id: string) => {
  const root = treeToList(menus.value, { pid: 'parentId' }).filter(
    (t: any) => t.id === id
  )[0];
  return root?.children ?? [];
};

const background = (image: string, size = 'cover') => {
  return image
    ? `background: url('${image}') no-repeat; background-size: ${size}; background-position: center center`
    : '';
};

const tiles = [
  {
    boxcls:
      'col-span-4 col-start-3 row-span-5 row-start-2 text-2xl 2xl:col-span-6 2xl:col-start-1 2xl:text-3xl',
    boxstyle: 'background:linear-gradient(225deg,#61b7b8,#0b357d)',
    cls: 'flex-col-reverse',
    maincls: '',
  },
  {
    boxcls:
      'col-span-4 col-start-7 row-span-5 text-2xl 2xl:col-span-6 2xl:text-3xl',
    boxstyle: 'background:linear-gradient(222deg,#e9c946,#0b357d 94%)',
  },
  {
    boxcls:
      'tile tile-l col-span-2 col-start-4 row-span-3 2xl:col-span-3 2xl:col-start-2 2xl:text-xl',
    subcls: 'text-xs 2xl:text-base',
    indicator: 'dot',
  },
  {
    boxcls:
      'tile tile-l col-span-1 col-start-6 row-span-3 2xl:col-span-2 2xl:text-xl',
    subcls: 'text-xs 2xl:text-base',
    indicator: 'dot',
  },
  {
    boxcls:
      'tile tile-r col-span-2 col-start-7 row-span-5 row-start-6 2xl:col-span-3 2xl:text-xl',
    subcls: 'text-xs 2xl:text-base',
    indicator: 'dash',
  },
  {
    boxcls:
      'tile tile-r col-span-2 col-start-9 row-span-5 row-start-6 2xl:col-span-3 2xl:text-xl',
    subcls: 'text-xs 2xl:text-base',
    indicator: 'dash',
  },
  {
    boxcls:
      'tile tile-l col-span-2 col-start-5 row-span-3 2xl:col-span-3 2xl:col-start-4 2xl:text-xl',
    subcls: 'text-xs 2xl:text-base',
    indicator: 'dot',
  },
];

const productOptions = computed(() => {
  const region = page.value?.regions?.find(
    (t: RegionDto) =>
      t.type === 'data-list' && t.extraProperties?.theme === '首页产品样式'
  );
  return (region?.children ?? [])
    .map((t: RegionDto) => ({ id: t.id, label: `${t.name} - ${t.summary}` }))
    .slice(2);
});

const formRef = ref();

const state = reactive({
  loading: false,
});

type ModelType = {
  name: string;
  company: string;
  content: string;
  phone: string;
  email: string;
  about: string[];
  securityCode: string;
};

const model = reactive<ModelType>({
  name: '',
  company: '',
  content: '',
  phone: '',
  email: '',
  about: [],
  securityCode: '',
});

const vertification = process.env.VERTIFICATION;

const handleSubmit = async () => {
  state.loading = true;
  try {
    if (vertification === 'phone') {
      await create({
        ...model,
        about: model.about.join(','),
      });
    } else if (vertification === 'none') {
      await withEmail({
        ...model,
        about: model.about.join(','),
      });
    }
    formRef.value?.reset();
    Notify.create(t('Contact.form.notify'));
  } finally {
    state.loading = false;
  }
};

const handleReset = () => {
  model.name = '';
  model.company = '';
  model.content = '';
  model.phone = '';
  model.email = '';
  model.about = [];
  model.securityCode = '';
};

const onClickVideoButton = (e: Event, url: string) => {
  if (/^#/.test(url)) {
    e.stopPropagation();
    VueScrollTo.scrollTo(url, {
      offset: -144,
    });
  }
};
</script>

<style scoped>
.q-carousel :deep(.q-carousel__arrow .q-icon) {
  font-size: 3.5rem;
  /* color: rgb(34 197 94); */
}

.button {
  position: absolute;
  top: calc(100vw * 4.5 / 16 + 50px);
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.opacity-08 {
  opacity: 0.8;
}

.vjs-custom-skin :deep(.video-js .vjs-big-play-button) {
  border-radius: 50% !important;
  width: 3em !important;
  height: 3em !important;
  font-size: 3.5em;
  line-height: 3em !important;
}

.vjs-control-text {
  display: none !important;
}

.indicator {
  background-color: var(--indicator-color);
}

.tile {
  background-color: var(--bg-secondary);

  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  overflow: hidden;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}

.tile.tile-l .tile-title {
  color: var(--color1);
}
.tile.tile-l .tile-indicator {
  background-color: var(--color1);
}
.tile.tile-r .tile-title {
  color: var(--indicator-color);
}
.tile.tile-r .tile-indicator {
  background-color: var(--indicator-color);
}

.tile .tile-btn {
  background-color: var(--indicator-color);
  display: none;
}

.tile:hover,
.tile:focus,
.tile:active {
  background-color: var(--color4);
}

.tile:hover .tile-title,
.tile:focus .tile-title,
.tile:active .tile-title {
  color: var(--text-primary);
}

.tile:hover .tile-indicator,
.tile:focus .tile-indicator,
.tile:active .tile-indicator {
  background-color: var(--text-primary);
}

.tile:hover .tile-btn,
.tile:focus .tile-btn,
.tile:active .tile-btn {
  display: inline-flex;
}

.highlight {
  background-color: var(--highlight-color);
}

/* Grow Shadow */
.hvr-grow-shadow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
}
.hvr-grow-shadow:hover,
.hvr-grow-shadow:focus,
.hvr-grow-shadow:active {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.h-dvh {
  height: 100dvh;
}
</style>

<template>
  <q-carousel v-bind="props" v-model="state.value">
    <template v-for="(region, i) in regions" :key="region.id">
      <q-carousel-slide :name="i" :img-src="region.image">
        <a
          v-if="region.url && /^http/.test(region.url)"
          class="absolute left-0 top-0 right-0 bottom-0"
          target="_blank"
          :href="tmpl(region.url, region)"
        ></a>
        <router-link
          v-if="region.url && !/^http/.test(region.url)"
          class="absolute left-0 top-0 right-0 bottom-0"
          :to="tmpl(region.url, region)"
        ></router-link>
        <div
          v-if="/\.mp4$|\.mp4?/.test(region.content ?? '')"
          class="absolute top-0 right-0 bottom-0 grid grid-cols-12 grid-rows-6"
        >
          <div v-if="region.url" class="col-start-2 row-start-4 flex items-end">
            <q-btn
              class="w-40 h-11"
              no-caps
              square
              padding="0"
              :to="tmpl(region.url, region)"
            >
              <div
                class="w-full h-11 flex justify-center items-center rounded text-white text-base horizontal-gradient"
              >
                {{ $t('Index.more') }}
              </div>
            </q-btn>
          </div>
          <div class="col-start-6 col-span-6 row-span-full flex items-center">
            <sv-video-player
              :src="region.content"
              style="
                width: calc(50vw);
                height: calc(50vw / 16 * 9);
                border-radius: 10px;
                overflow: hidden;
              "
            />
          </div>
        </div>
        <div
          v-else
          class="h-full pl-36 pr-96 flex flex-col justify-center gap-8 text-primary"
        >
          <div class="text-5xl font-bold">
            {{ region.content }}
          </div>
          <div v-html="region.summary?.replace(/[\r\n]/g, '<br/>')"></div>
        </div>
      </q-carousel-slide>
    </template>
    <template #navigation-icon="navigation">
      <slot name="navigation-icon" v-bind="navigation"></slot>
    </template>
  </q-carousel>
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import { QCarouselProps } from 'quasar';
import { RegionDto } from 'src/api/types';
import tmpl from 'src/utils/tmpl';
import SvVideoPlayer from 'src/components/SvVideoPlayer.vue';

export interface SvCarouselProps extends QCarouselProps {
  regions: RegionDto[];
}

const props = defineProps<SvCarouselProps>();

const state = reactive({
  value: 0,
});
</script>

<style scoped type="css">
.horizontal-gradient {
  background: linear-gradient(to right, #2fc725, #009de9);
}
</style>

<template>
  <div class="flex justify-between mt-8 -gap-x-8">
    <template v-for="article in articles" :key="article.id">
      <div v-if="!article.activeDate" class="col"></div>
      <q-card v-else class="col flex flex-col justify-between">
        <div>
          <router-link :to="`/news/${article.id}`">
            <q-img
              v-if="article.image"
              :src="article.image"
              :alt="article.title"
              loading="lazy"
            >
              <template #loading>
                <q-spinner-dots color="primary" size="1.5rem" />
              </template>
            </q-img>
          </router-link>

          <q-card-section>
            <router-link
              :to="`/news/${article.id}`"
              class="text-2xl h-16 hover:text-green-600 cursor-pointer text-multiline-ellipsis"
            >
              {{ article.title }}
            </router-link>
            <div class="text-subtitle2">
              <p v-for="(p, i) in (article.summary ?? '').split('\n')" :key="i">
                {{ p }}
              </p>
            </div>
          </q-card-section>
        </div>

        <div>
          <q-separator />

          <q-card-section>
            <div class="w-full flex justify-between items-center px-2">
              <span>{{ article.activeDate?.substring(0, 10) }}</span>
              <q-icon class="text-xl" name="mdi-chevron-right" />
            </div>
          </q-card-section>
        </div>
      </q-card>
    </template>
  </div>
</template>

<script setup lang="ts">
import { RegionDto, ArticleDto, TagType } from 'src/api/types';

const props = defineProps<{
  region: RegionDto;
  articles: ArticleDto[];
}>();

const max = (props.region.extraProperties?.max as number) ?? 3;
const articles = props.articles.slice(0, max);
if (articles.length < max) {
  for (let i = 0; i <= max - articles.length; i++) {
    articles.splice(articles.length, 0, {
      id: i.toString(),
      category: '',
      keywords: {
        type: 'default' as TagType,
      },
      creationTime: '',
    });
  }
}
</script>

<style>
.text-multiline-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>

<template>
  <div>
    <q-card class="mb-8 p-4 bg-transparent">
      <q-tabs
        v-model="state.tab"
        class="flex justify-center"
        content-class="flex justify-between"
        active-class="text-green-600"
        indicator-color="transparent"
      >
        <template v-for="(page, i) in pages" :key="page.id">
          <q-tab
            class="flex justify-start"
            v-if="page.children?.length"
            :name="i"
            :ripple="false"
            no-caps
          >
            <div class="font-bold text-2xl">
              {{ page.title }}
            </div>
          </q-tab>
        </template>
      </q-tabs>
    </q-card>
    <q-tab-panels
      class="bg-transparent"
      style="height: 32rem"
      v-model="state.tab"
      animated
      swipeable
      vertical
      transition-prev="jump-up"
      transition-next="jump-up"
    >
      <template v-for="(page, i) in pages" :key="page.id">
        <q-tab-panel class="p-1" v-if="page.children?.length" :name="i">
          <SvProjectTabs :pages="page.children" />
        </q-tab-panel>
      </template>
    </q-tab-panels>
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import { PageWithChildrenDto } from 'stores/modules/page';
import SvProjectTabs from './SvProjectTabs.vue';

defineProps<{
  pages: PageWithChildrenDto[];
}>();

const state = reactive({
  tab: 0,
});
</script>

<style scoped>
.horizontal-gradient {
  background: linear-gradient(to right, #2fc725, #009de9);
}
</style>

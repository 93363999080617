<template>
  <div class="h-full flex -gap-x-8">
    <q-card class="flex-none bg-transparent" style="min-width: 23rem">
      <q-tabs
        v-model="state.tab"
        active-class="horizontal-gradient-0.2"
        indicator-color="transparent"
        vertical
        no-caps
      >
        <div class="h-full flex flex-col justify-between">
          <div class="flex justify-center py-2">
            <q-btn
              class="text-xl hover:text-green-600"
              icon="mdi-menu-up"
              flat
              fab-mini
              no-caps
              @click="prev"
            ></q-btn>
          </div>
          <div
            class="flex flex-col justify-start -gap-y-4"
            style="height: calc(100% - 7rem)"
          >
            <div
              v-for="(page, i) in pages"
              :key="page.id"
              :class="`mx-8 shadow shadow-gray-300 ${
                i < state.start || i > state.end ? 'hidden' : ''
              }`"
            >
              <q-tab
                class="flex justify-start"
                :name="i"
                :ripple="false"
                no-caps
              >
                <div class="flex items-center -gap-x-2 pl-1">
                  <img
                    v-if="page.icon"
                    class="w-6 h-6"
                    :src="page.icon"
                    alt="icon"
                  />
                  <span>{{ page.title }}</span>
                </div>
              </q-tab>
            </div>
          </div>
          <div class="flex justify-center py-2">
            <q-btn
              class="text-xl hover:text-green-600"
              icon="mdi-menu-down"
              flat
              fab-mini
              no-caps
              @click="next"
            ></q-btn>
          </div>
        </div>
      </q-tabs>
    </q-card>
    <q-card class="flex-1 bg-transparent">
      <q-tab-panels
        class="h-full bg-transparent"
        v-model="state.tab"
        animated
        swipeable
        vertical
        transition-prev="jump-up"
        transition-next="jump-up"
      >
        <template v-for="(page, i) in pages" :key="page.id">
          <q-tab-panel
            class="px-10 py-12"
            :name="i"
            :style="`background: url('${page.image}') no-repeat bottom right; background-size: auto 100%; background-position: 70%`"
          >
            <div class="grid content-between h-full">
              <div class="w-2/5">
                <div class="text-2xl">{{ page.title }}</div>
                <div class="mt-4 text-gray-500">
                  <p
                    v-for="(p, i) in (page.summary ?? '').split('\n')"
                    :key="i"
                  >
                    {{ p }}
                  </p>
                </div>
                <router-link
                  class="flex -gap-x-2 items-center mt-8 text-green-600"
                  :to="tmpl(page.url!, page)"
                >
                  <span>{{ t('SvProjectTabs.more') }}</span>
                  <q-icon
                    size="20px"
                    name="mdi-arrow-right-drop-circle-outline"
                  />
                </router-link>
              </div>

              <q-btn
                class="w-40 h-11"
                no-caps
                square
                padding="0"
                to="/about/contact"
              >
                <div
                  class="w-full h-full flex justify-center items-center text-white text-base horizontal-gradient"
                >
                  {{ t('SvProjectTabs.consult') }}
                </div>
              </q-btn>
            </div>
          </q-tab-panel>
        </template>
      </q-tab-panels>
    </q-card>
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import { PageWithChildrenDto } from 'stores/modules/page';
import tmpl from 'src/utils/tmpl';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  pages: PageWithChildrenDto[];
}>();

const state = reactive({
  tab: 0,
  start: 0,
  end: Math.min(props.pages.length - 1, 5),
});

const prev = () => {
  if (state.tab > state.start) {
    state.tab--;
  } else if (state.start > 0) {
    state.start--;
    state.end--;
    // if (state.tab > state.end) state.tab = state.end;
    if (state.tab === state.start + 1) state.tab--;
  }
};

const next = () => {
  if (state.tab < state.end) {
    state.tab++;
  } else if (state.end < props.pages.length - 1) {
    state.start++;
    state.end++;
    // if (state.tab < state.start) state.tab = state.start;
    if (state.tab === state.end - 1) state.tab++;
  }
};
</script>

<style scoped>
.horizontal-gradient {
  background: linear-gradient(to right, #2fc725, #009de9);
}

.horizontal-gradient-0\.2 {
  background: linear-gradient(to right, #2fc72522, #009de922);
}
</style>
